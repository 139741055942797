/* eslint-disable react/no-array-index-key */

import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Typography } from "@mui/material";
import { FormEvent, useContext, useMemo, useState } from "react";
import { ChooseRespondentComponent, ChooseRespondentHTMLComponent } from "../_components/ChooseRespondentComponent";
import { LoadingFormButton } from "../_components/LoadingFormButton";
import { useUser } from "../_hooks/useUser";
import { Relation } from "../_interfaces/OrderInterface";
import strings from '../_strings/ChooseRespondentsPage.json';
import relationStrings from '../_strings/Relations.json';
import inputStrings from '../_strings/Input.json';
import { LanguageContext } from "../_contexts/LanguageContext";
import { SLink } from "../_components/SLink";
import { useForm } from "../_helpers/useForm";
import { api } from "../_services/apiServices";
import browserHistory from "../_helpers/History";

const ChooseRespondentsPage = ({ languages }: { languages: string[] }): JSX.Element => {

    const maxRespondents = 12;
    const minRespondents = 2;

    const { user, mutate } = useUser();

    const defaults = useMemo(() => Array.from({ length: maxRespondents }, (x, index) => ({
        name: { error: "", value:  "" },
        email: { error: "", value:  "" },
        relation: { error: "", value:  Relation.colleague },
        language: { error: "", value:  'swedish' },
        valid: false,
        key: (Math.random() + 1).toString(36).substring(7) 
    })), []);
    
    const extraCheck = (key: string, valuesToBeSet: any): string | undefined => {
        const { [key]: { value } } = valuesToBeSet
        if (key === 'email') {
            if (value === user.email) {
                return `Du kan inte ange din egen epostadress`
            }
            if (values.reduce((tot, current) => tot + (current.email.value === value ? 1 : 0), 0) > 1) {
                return `Epostadressen ${value} är redan angiven`
            }
        }
        return undefined
    }
    const { handleInputValues, values, valid, handleInputValidation } = useForm(defaults, extraCheck)

    const { language: languageContext } = useContext(LanguageContext);

    const [openFaultyInputDialog, toggleOpenFaultyInputDialog] = useState(false);
    const [openFewInRelationInputDialog, toggleFewInRelationInputDialog] = useState(false);

    const thereAreShortageOfRespondents = (): {relation: string, respondents: number}[] => {
        const relations: { Relation: number } = values.filter(v => v.valid).reduce((tot, current) => {
            const relation = current.relation.value
            const respondents = tot[relation] || 0
            return { ...tot, [relation]: respondents + 1 }
        }, {})
        const res: { relation: string, respondents: number }[] = [];
        Object.entries(relations).forEach(([relation, number]) => {
            if (relation !== Relation.superior && number < 2) {
                res.push({ relation, respondents: number })
            }
        })
        return res;
    }

    const handleSubmit = (event?: FormEvent, approved?: boolean): Promise<boolean> => {
        if (event){
            event.preventDefault();
        }
        if (valid) {
            // no event means it was approved to ignore inputs with invalid values:
            if (event && values.some(value => 
                !value.valid && value.name.value !== '' && value.email.value !== ''
            )) {
                toggleOpenFaultyInputDialog(true)
                return Promise.resolve(false);
            }
            if (thereAreShortageOfRespondents().length > 0 && !approved) {
                toggleFewInRelationInputDialog(true)
                return Promise.resolve(false);
            }
            return api().sendChooseRespondentsForm(
                values.filter(value => value.valid)
                .map(respondent => ({
                    name: respondent.name.value,
                    email: respondent.email.value,
                    relation: respondent.relation.value,
                    language: respondent.language.value
                }))).then(savedRespondents => {
                if (savedRespondents.length >= minRespondents) {
                    return mutate().then(() => {
                        browserHistory.push('/form', { part: user.parts[0] });
                        return true;
                    })
                }
                return false;
            }).catch((error: unknown) => {
                console.log(error)
                return false
            });
        }
        return Promise.resolve(false);
    }

    return (
        <Grid sx={{maxWidth: "1000px"}}>
            <Typography mt='10vh'>{ strings[languageContext].intro1 }<q>{ relationStrings[languageContext].other }</q>.</Typography>
            <Typography mt='2vh'>{ strings[languageContext].intro2 }
                <SLink href="/faq">{ strings[languageContext].faq }</SLink>
            { strings[languageContext].intro3 }.
            </Typography>
            <Typography mt='2vh'>{ strings[languageContext].intro4 }
                <q>{ inputStrings[languageContext].send }</q>
                { strings[languageContext].intro5 } 
                {/* <b>{ strings[languageContext].intro6 }</b> */}
            </Typography>

            <Box sx={{ border:'1px dotted grey', padding:'10px 10px 0px 10px', margin:'10px 0px 24px 0px' }}>
                <Typography>{ strings[languageContext].noSelf }</Typography>
                <ChooseRespondentHTMLComponent
                    defaultName = {user.name === '' ? strings[languageContext].yourName : user.name}
                    defaultEmail = {user.email}
                    defaultLanguage = {languageContext}
                    disabled
                    language={languageContext}
                    languages={languages}
                    index={100}
                    value=''
                    handleInputValues={() => {}}
                    handleValidation={() => {}}
                />
            </Box>
            {defaults.length > 0 && defaults.map((respondent, index) =>
            <ChooseRespondentComponent
                    key={index}
                    index={index}
                    value={values[index]}
                    handleInputValues={handleInputValues}
                    handleValidation={handleInputValidation}
                    selected={index === 0}
                    language={languageContext}
                    languages={languages}
                />
            )}
            <Grid item xs={12} md={4} lg={2}>
                <LoadingFormButton 
                    disabled = { !valid }
                    sx={{ width: "100%", padding:'1vh', marginTop:'16px', marginBottom:'8px' }}
                    onClick={handleSubmit}
                >
                    { inputStrings[languageContext].send }
                </LoadingFormButton>
            </Grid>
            <Dialog open={openFaultyInputDialog} onClose={() => toggleOpenFaultyInputDialog(false)}>
                <DialogTitle>{ strings[languageContext].confirmSubmittingFewerRespondentsDialog }</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        { strings[languageContext].confirmSubmittingFewerRespondentsDialogContent1 }
                        <br/><br/>
                        {
                            values.filter(value => !value.valid && value.name.value !== '' && value.email.value !== '')
                            .map(r => `${r.name.value}, ${r.email.value}`)
                        }
                        <br/><br/>
                        { strings[languageContext].confirmSubmittingFewerRespondentsDialogContent2 }
                        <b>{ inputStrings[languageContext].confirm }</b><br/><br/>
                        { strings[languageContext].confirmSubmittingFewerRespondentsDialogContent3 }
                        <b>{ inputStrings[languageContext].abort }</b>
                        { strings[languageContext].confirmSubmittingFewerRespondentsDialogContent4 }
                    </DialogContentText>
                    <DialogActions>
                        <Button onClick={() => toggleOpenFaultyInputDialog(false)} autoFocus>{inputStrings[languageContext].abort}</Button>
                        <Button
                        onClick={() => {
                            toggleOpenFaultyInputDialog(false)
                            handleSubmit()
                        }}>
                            {inputStrings[languageContext].confirm}
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
            <Dialog open={openFewInRelationInputDialog} onClose={() => toggleFewInRelationInputDialog(false)}>
                <DialogTitle>{ strings[languageContext].confirmSubmittingShortRelationDialog }</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        { strings[languageContext].confirmSubmittingShortRelationDialogContent1 }
                        <br/><br/>
                        {
                            openFewInRelationInputDialog && thereAreShortageOfRespondents().map(r => 
                                <Typography component="span" style={{display: 'block'}} key={r.relation}>{relationStrings[languageContext][r.relation]} - { strings[languageContext].confirmSubmittingShortRelationDialogContent2 } {r.respondents}, { strings[languageContext].confirmSubmittingShortRelationDialogContent3 }</Typography>
                            )
                        }
                        <br/>
                        { strings[languageContext].confirmSubmittingShortRelationDialogContent4 }
                        <b>{ inputStrings[languageContext].confirm }</b><br/><br/>
                        { strings[languageContext].confirmSubmittingShortRelationDialogContent5 }
                        <b>{ inputStrings[languageContext].abort }</b>
                        { strings[languageContext].confirmSubmittingShortRelationDialogContent6 }
                    </DialogContentText>
                    <DialogActions>
                        <Button onClick={() => toggleFewInRelationInputDialog(false)} autoFocus>{inputStrings[languageContext].abort}</Button>
                        <Button
                        onClick={() => {
                            toggleFewInRelationInputDialog(false)
                            handleSubmit(undefined, true)
                        }}>
                            {inputStrings[languageContext].confirm}
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        </Grid>
    );
}

export { ChooseRespondentsPage }