export const gqlRequest = {
    // name, initials, email, roles, token, company, phone, address, postal, city,
    // tested
    signup: 
    `mutation signup($email:String!, $password:String!) {
        signup(email:$email, password:$password) {
        user {
            name, initials, email, roles, company, phone, address, postal, city,
            parts {
                link, language, relation, started, client {
                    name, gender, handedIn
                },
                coach { name, email }
            },
            licenses {
                label, key, languages, price, type
            }
        },
        }
    }`,
    // tested
    hello:
    `query hello($name:String) {
            hello(name:$name)
    }`,
    // `query Linklogin($hash:String!) {
    //     linkLogin(hash:$hash) { 
    //     user { 
    //         token
    //     },
    //     }
    // }`,
    // used in tests, but not explicitly tested
    linkLogin2:
    `query Linklogin($hash:String!)`,
    // deprecated
    // linkLogin:
    // `query Linklogin($hash:String!) {
    //     linkLogin(hash:$hash) { 
    //     user {
    //         name, email, roles, token, parts {
    //         link, language, relation, started, client {
    //             name, gender
    //         }
    //         }
    //     },
    //     }
    // }`,
    // used in tests
    // deprecated?
    loginAsync2:
    `query Login($email:String!, $password:String!)`,
    // `query Login($email:String!, $password:String!) {
    //     login(email:$email, password:$password) { 
    //     user { 
    //         token 
    //     }
    //     }
    // }`,
    // loginAsync3:
    // `{"email":$email, "password":$password}`,
    // deprecated
    // loginAsync:
    // `query Login($email:String!, $password:String!) {
    //     login(email:$email, password:$password) { 
    //     user {
    //         name, initials, email, roles, token, company, phone, address, postal, city,
    //         parts {
    //             link, language, relation, started, client {
    //                 name, gender
    //             }
    //         },
    //         licenses {
    //             label, key, languages, price, type
    //         }
    //     },
    //     }
    // }`,
    // tested
    // sendOrderForm:
    placeOrder:
    `mutation($values:[OrderInput!]) {
        placeOrder(orders:$values) {
            company, endDate, reminderDate
        }
    }`,
    placeLicenseOrder:
    `mutation($values:[LicenseOrderInput!]) {
        placeLicenseOrder(licenses:$values){ key }
    }`,
    // label, key, languages, price, type, discountPrice, discountLimit, pcs
    // sendEmailForm:
    editEmail:
    `mutation ($values:EmailInput!) {
        editEmail(values:$values) {
            description, subject, body, id, language, tag, placeholders {
                key
            }
        }
    }`,
    // description, subject, body, id, language

    // name, email, roles, token, company, phone, address, postal, city,
    // tested
    // sendAccountForm:
    editUser:
    `mutation ($values:UserInput!) {
        editUser(userData:$values) {
            name, email, roles, company, phone, address, postal, city,
            parts {
                link, language, relation, started, client {
                    name, gender, handedIn
                },
                coach { name, email }
            },
            licenses {
                label, key, languages, price, type
            }            
        }
    }`,
    changeEmailAddress: 
    `mutation ($newEmail: String!) {
        changeEmailAddress(newEmail: $newEmail) {
            status
        }
    }`,
    confirmEmailChange:
    `mutation ($link: String!) {
        confirmEmailChange(link: $link) {
            status
        }
    }`,
    resetPassword:
    `mutation ($email: String!){
        resetPassword(email: $email) {
            status
        }
    }`,
    confirmPasswordChange:
    `mutation ($link: String!, $password: String!) {
        confirmPasswordChange(link: $link, password: $password) {
            status
        }
    }`,
    changePassword:
    `mutation ($oldPassword: String!, $newPassword: String!) {
        changePassword(oldPassword: $oldPassword, newPassword: $newPassword) {
            status
        }
    }`,
    // tested
    // sendChooseRespondentsForm:
    setRespondents:
    `mutation ($respondents:[RespondentInput]!) {
        setRespondents(respondents: $respondents) {
            name, email, relation, language
        }
    }`,
    // tested
    // getOrders:
    orders:
    `query {
        orders {
            id, company, endDate, ended, reminderDate, started, languageOptions
            client {
                name, email, gender, language, relation, handedIn
            },
            respondents {
                name, email, gender, language, relation, handedIn
            }
        }
    }`,
    // quote {
    //     total, answers
    // },
    // answers {
    //     name, email, gender, language, relation
    // },
    sendEmail:
    `query ($type:String, $to:String, $order:OrderInput) {
        sendEmail(type:$type, to:$to, order:$order)
    }`,
    // getEmails:
    emails:
    `query($license:LicenseInput) {
        emails(license:$license) {
            description, subject, body, id, language, tag, placeholders {
                key
            }
        }
    }`,
    // getForm:
    forms:
    `query($license: LicenseInput, $link: String) {
        forms(license: $license, link: $link) {
            id, name, sections {
                id, index, text, groups {
                    id, index, text, questions {
                        id, index, text
                    }
                }
            }
        }
    }`,
    // sendTestEmail:
    testEmail:
    `query testEmail($email:EmailInput!) {
        testEmail(email:$email)
    }`,
    allLicenses:
    `query {
        allLicenses {
            key, label, languages, price, type, logo
        }
    }`,
    // newUser: 
    addUser: 
    `mutation addUser($user:UserInput!) {
        addUser(userArgs:$user) {
            name
        }
    }`,
    // newEmail:
    addEmail:
    `mutation($email:EmailInput!, $license:LicenseInput!) {
        addEmail(email:$email, license:$license) {
            id
        }
    }`,
    // newLicense:
    addLicense:
    `mutation($license:LicenseInput!) {
        addLicense(license:$license) {
            key, label, languages, price, type, logo
        }
    }`,
    // tested
    // sendAnswers:
    handInForm:
    `mutation($data: HandInFormInput) {
        handInForm(data: $data) {
            comment, familiarity,
            answers {
                id, value
            },
            order {
                link, language, relation
            }
        }
    }`,
    // `mutation($data: HandInFormInput) {
    //     handInForm(data: $data) {
    //         comment, familiarity, answers {
    //             id, value
    //         }, order {
    //             link, language, relation, client {
    //                 name, email
    //             }
    //         }
    //     }
    // }`,
    // `mutation($data: HandInFormInput) {
    //     handInForm(data: $data) {
    //         link, language, relation, started, client {
    //             name, gender
    //         }
    //     }
    // }`,
    // `query testEmail($email:EmailInput!) {
    //     testEmail(email:$email)
    // }`,

    getClientStatus:
    `query getClientStatus{ 
        getClientStatus{ 
            ended, endDate, groups {
                relation, status, answers, total
            }, respondents {
                name, email, relation
            }
        } 
    }`,
    addRespondent:
    `mutation ($respondent:RespondentInput!) {
        addRespondent(respondent: $respondent) {
            name, email, relation, language
        }
    }`,
    // tested
    editRespondent:
    `mutation($oldValues: RespondentInput!, $newValues: RespondentInput!, $order: EditOrderInput!) {
        editRespondent(oldValues: $oldValues, newValues: $newValues, order: $order) {
            name, email, language, relation, gender
        }
    }`,
    // tested
    editOrder:
    `mutation($oldOrder: EditOrderInput!, $newOrder: EditOrderInput!) {
        editOrder(oldOrder: $oldOrder, newOrder: $newOrder) {
            id, company, endDate, ended, reminderDate, started, languageOptions,
            client {
                name, email, gender, language, relation, handedIn
            },
            respondents {
                name, email, gender, language, relation, handedIn
            }
        }
    }`,
    report:
    `query($order: EditOrderInput!) {
        report(order: $order)
    }`,
    // editQuestions:
    // `mutation() {
    //     editQuestions()
    // }`,
    // name, initials, email, roles, token, company, phone, address, postal, city,
    // used in tests
    useUser:
    `query {
        user {
            name, initials, email, roles, company, phone, address, postal, city,
            parts {
                link, language, relation, started, client {
                    name, gender, handedIn
                },
                coach { name, email }
            },
            licenses {
                label, key, languages, price, type, discountPrice, discountLimit, pcs
            }
        }
    }`,
    // name, email, roles, token, company, phone, address, postal, city,
    useLogin:
    `query Login($email:String!, $password:String!) {
        login(email:$email, password:$password) { 
            user {
                name, email, roles, company, phone, address, postal, city,
                parts {
                    link, language, relation, started, client {
                        name, gender, handedIn
                    },
                    coach { name, email }
                },
                    licenses {
                    label, key, languages, price, type
                }
            },
        }
    }`
}